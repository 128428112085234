const autoParse = require('auto-parse');

export default function parseInput(value) {
  if (typeof autoParse(value) === 'boolean') {
    return autoParse(value);
  } else if (typeof value === 'number') {
    return value.toString();
  } else if (typeof value === 'object') {
    return value;
  }
  // handle numbers as strings
  else if (typeof autoParse(value.replace(/,/g, '.')) === 'number') {
    return value.replace(/,/g, '.');
  }
  // save as string if input is not recognized as number
  else {
    return value;
  }
}
