import React, { useState, useEffect, useCallback, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, TextField, Select, MenuItem, FormControl, InputLabel, Typography, Divider, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Grid, IconButton } from '@material-ui/core';
import { DeleteOutline, Fullscreen } from '@material-ui/icons';
import Api from 'api/index';
import { dateToDateTimeString } from 'assets/js/common';
import { getExchangePrettyName } from 'assets/js/gunbot.helper';
import _ from 'lodash'
import { useSelector } from 'react-redux';
import { selectConfigPairs } from 'redux/selectors';

const useStyles = makeStyles(theme => ({
  icons: {
    color: theme.palette.text.primary,
  },
}));

const Notes = (props) => {
  const { currentPair, currentExchange, useOverlay } = props;
  const allPairs = useSelector(selectConfigPairs);
  const exchanges = Object.keys(allPairs)
  const exchangePairs = function (exchange) {
    if (!_.isNil(allPairs?.[exchange])) {
      return Object.keys(allPairs[exchange])
    }
    else {
      return []
    }
  }
  const classes = useStyles();
  const textFieldRef = useRef(null);
  const [notes, setNotes] = useState([]);
  const [filteredNotes, setFilteredNotes] = useState([]);
  const [selectedPair, setSelectedPair] = useState('all');
  const [selectedExchange, setSelectedExchange] = useState(Object.keys(allPairs)[0] || '');
  const [noteText, setNoteText] = useState('');
  const [showOverlay, setShowOverlay] = useState(false);
  const [showNotes, setShowNotes] = useState(true);

  const [currentPage, setCurrentPage] = useState(0);
  const notesPerPage = 5;
  const pagesCount = Math.ceil(filteredNotes.length / notesPerPage);
  const startIndex = currentPage * notesPerPage;
  const visibleNotes = filteredNotes.slice(startIndex, startIndex + notesPerPage);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  useEffect(() => {
    // Focus the TextField when noteText changes
    if (textFieldRef.current) {
      textFieldRef.current.focus();
    }
  }, [noteText, filteredNotes, selectedPair, selectedExchange]);


  useEffect(() => {
    fetchNotes();
  }, []);

  useEffect(() => {
    handleFilterChange();
  }, [selectedExchange, selectedPair, notes]);

  useEffect(() => {
    if (selectedExchange !== currentExchange) {
      setSelectedExchange(currentExchange)
    }
  }, [currentExchange]);

  useEffect(() => {
    if (_.isNil(selectedPair) || selectedPair === 'Loading' || selectedPair === 'all') {
      if (!_.isNil(currentPair) && currentPair !== 'Loading') {
        setSelectedPair(currentPair)
      }
    }
  }, [currentPair]);


  const fetchNotes = async () => {
    try {
      const response = await Api.getNotes();
      if (Array.isArray(response)) {
        setNotes(response);
      }
    } catch (error) {
      // do nothing
    }
  };

  const handleDeleteNote = async (note) => {
    const updatedNotes = notes.filter((n) => n.date !== note.date);
    setNotes(updatedNotes);
    await Api.setNotes(updatedNotes);
  };

  const handleMode = () => {
    setShowNotes(!showNotes)
  };

  const saveNote = async () => {
    try {
      const newNote = {
        text: noteText,
        pair: selectedPair,
        exchange: selectedExchange,
        date: Date.now()
      };
      const allNotes = [newNote, ...notes]
      setNoteText('');
      setSelectedPair('all');
      setNotes(allNotes)
      setShowNotes(true)
      await Api.setNotes(allNotes);
    } catch (error) {
      // do nothing
    }
  };

  const handlePairChange = useCallback((event) => {
    setSelectedPair(event.target.value);
  }, []);

  const handleExchangeChange = useCallback((event) => {
    setSelectedExchange(event.target.value);
    if (event.target.value === 'all') {
      setSelectedPair('all');
    }
  }, []);

  const handleNoteTextChange = useCallback((event) => {
    setNoteText(event.target.value);
    if (textFieldRef.current) {
      textFieldRef.current.focus();
    }
  }, [noteText]);


  const handleFilterChange = () => {
    if (selectedPair === 'all' && selectedExchange === 'all') {
      setFilteredNotes(notes);
    }
    else if (selectedPair === 'all') {
      const filtered = notes.filter((note) => note.exchange === selectedExchange);
      setFilteredNotes(filtered);
    }
    else if (selectedExchange === 'all') {
      const filtered = notes.filter((note) => note.pair === selectedPair || note.pair === 'all');
      setFilteredNotes(filtered);
    }
    else {
      const filtered = notes.filter(
        (note) => note.pair === selectedPair && note.exchange === selectedExchange
      );
      setFilteredNotes(filtered);
    }
  };

  const handleOverlayOpen = () => {
    setShowOverlay(true);
  };

  const handleOverlayClose = () => {
    setShowOverlay(false);
  };

  const renderNewLines = (text) => {
    const lines = text.split('\n');
    return lines.map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  const Notes = () => {
    return (
      <>
        <Divider style={{ margin: '1rem 0' }} />
        {visibleNotes.length === 0 ? (
          <Typography variant="body2">There are no notes for this pair and exchange.</Typography>
        ) : (
          visibleNotes.map((note) => (
            <Grid container key={note.date}>
              <Grid item xs={11} align="left">
                <Typography variant="subtitle2">
                  {dateToDateTimeString(note.date)} | {note.pair} / {note.exchange}
                </Typography>
              </Grid>
              <Grid item xs={1} align="right">
                <IconButton onClick={() => handleDeleteNote(note)} size="small" className={classes.icons}>
                  <DeleteOutline
                  />
                </IconButton>
              </Grid>
              <Grid item xs={12} align="left">
                <Typography variant="body2">{renderNewLines(note.text)}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider style={{ margin: "0.5rem 0" }} />
              </Grid>
            </Grid>
          ))
        )}

        <Grid container style={{ marginTop: '1rem' }}>
          <Grid item xs={6} align="left">
            <Button onClick={handlePreviousPage} disabled={currentPage === 0 || filteredNotes.length <= 5} size="small" variant="outlined">
              Newer
            </Button>
          </Grid>
          <Grid item xs={6} align="right">
            <Button onClick={handleNextPage} disabled={currentPage === pagesCount - 1 || filteredNotes.length <= 5} size="small" variant="outlined">
              Older
            </Button>
          </Grid>
        </Grid>
      </>
    )
  }

  const NotesContainer = () => {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item container spacing={2}>
            <Grid item xs={6} sm={6}>
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel
                >
                  Pair</InputLabel>
                <Select
                  value={selectedPair}
                  onChange={handlePairChange}
                  label="Pair"
                  style={{ textAlign: 'left' }}

                >
                  <MenuItem value='all'>All</MenuItem>
                  {exchangePairs(selectedExchange).map((pair) => (
                    <MenuItem value={pair} key={pair}>{pair}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={6}>
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel
                >
                  Exchange</InputLabel>
                <Select
                  value={selectedExchange || ''}
                  onChange={handleExchangeChange}
                  label="Exchange"
                  style={{ textAlign: 'left' }}
                >
                  <MenuItem value='all'>All</MenuItem>
                  {exchanges.map((exchange) => (
                    <MenuItem key={exchange} value={exchange}>{getExchangePrettyName(exchange)}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {!showNotes &&
              <Grid item xs={12}>
                <TextField
                  label="Write new note"
                  value={noteText}
                  onChange={handleNoteTextChange}
                  multiline
                  minRows={3}
                  variant="outlined"
                  fullWidth
                  inputRef={textFieldRef}
                  autoFocus
                  onFocus={(e) =>
                    e.currentTarget.setSelectionRange(
                      e.currentTarget.value.length,
                      e.currentTarget.value.length
                    )}
                />
              </Grid>
            }
            {!showNotes &&
              <Grid item xs={12} sm={12}>
                <Button variant="outlined" color="primary" onClick={saveNote} size="small" fullWidth>
                  Save note
                </Button>
              </Grid>
            }
            {showNotes &&
              <>
                <Grid item xs={showOverlay || useOverlay ? 12 : 10} sm={showOverlay || useOverlay ? 12 : 10}>
                  <Button variant="outlined" color="primary" onClick={handleMode} size="small" fullWidth>
                    Write new note
                  </Button>
                </Grid>
                {(!showOverlay && !useOverlay) &&
                  <Grid item xs={2} sm={2} align="right">
                    <IconButton onClick={handleOverlayOpen} size="small" className={classes.icons}>
                      <Fullscreen
                      />
                    </IconButton>
                  </Grid>
                }

              </>

            }
            {!showNotes &&
              <Grid item xs={12} sm={12}>
                <Button variant="outlined" onClick={handleMode} size="small" fullWidth>
                  Discard new note
                </Button>
              </Grid>
            }
          </Grid>
        </Grid>


        {showNotes &&
          <Notes />
        }
      </>
    )
  }

  return (

    <div style={{ minWidth: '100%', maxWidth: '100%' }}>
      {
        !useOverlay &&
        <NotesContainer />
      }



      <Dialog
        open={showOverlay || useOverlay}
        onClose={handleOverlayClose}
        maxWidth={"md"}
        fullWidth
        PaperProps={{
          style: useOverlay ? {
            height: '100vh',
            marginTop: '96px',
            minWidth: '100%'
          } : {}
        }}
      >
        <DialogTitle>Notes</DialogTitle>
        <DialogContent>
          <NotesContainer />
        </DialogContent>
        {
          !useOverlay &&
          <DialogActions style={{ marginTop: '1rem' }}>
            <Button onClick={handleOverlayClose} color="primary">
              Close
          </Button>
          </DialogActions>
        }

      </Dialog>
    </div>
  );
};

export default Notes;